import React from "react";
import * as Icon from "react-feather";
import {
  UilNotes,
  UilParcel,
  UilShop,
  UilMegaphone,
} from "@iconscout/react-unicons";

const horizontalMenuConfig = [
  {
    id: "home",
    title: "home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["ADMIN", "FLORIST"],
    navLink: "/",
  },
  {
    id: "commands",
    title: "orders",
    type: "item",
    icon: <UilNotes size={20} />,
    permissions: ["ADMIN", "FLORIST"],
    navLink: "/commandes",
  },
  {
    id: "produc-list",
    title: "products",
    type: "collapse",
    icon: <UilParcel size={20} />,
    permissions: ["ADMIN", "FLORIST"],

    children: [
      {
        id: "prods",
        title: "productTitle",
        type: "item",
        // icon: <UilParcel size={12} />,
        permissions: ["FLORIST"],
        navLink: "/productList",
      },
      {
        id: "Legal",
        title: "categories",
        type: "item",
        // icon: <Icon.List size={12} />,
        permissions: ["ADMIN" /* , "FLORIST" */],
        navLink: "/categories",
      },
      {
        id: "templates",
        title: "productOptions",
        type: "item",
        // icon: <Icon.Layers size={20} />,
        permissions: ["ADMIN", "FLORIST"],
        navLink: "/optionTemplates",
      },
      {
        id: "tags",
        title: "Tags",
        type: "item",
        // icon: <Icon.Layers size={20} />,
        permissions: ["ADMIN"],
        navLink: "/tags",
      },
      {
        id: "addons",
        title: "productAddons",
        type: "item",
        // icon: <Icon.Layers size={20} />,
        permissions: ["FLORIST"],
        navLink: "/addons",
      },
      {
        id: "mentionsLabels",
        title: "mentionsLabels",
        type: "item",
        // icon: <Icon.Layers size={20} />,
        permissions: ["FLORIST"],
        navLink: "/mentions",
      },
    ],
  },
  {
    id: "discount",
    title: "discount",
    type: "item",
    icon: <Icon.Gift size={20} />,
    permissions: ["ADMIN"],
    navLink: "/discount",
  },
  {
    id: "clients",
    title: "customers",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["ADMIN"],
    navLink: "/clients",
  },
  {
    id: "pros",
    title: "stores",
    type: "item",
    icon: <UilShop size={20} />,
    permissions: ["ADMIN"],
    navLink: "/pros",
  },
  {
    id: "settings",
    title: "settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["ADMIN"],
    navLink: "/settings",
  },
  {
    id: "delivery",
    title: "deliveryMethod",
    type: "item",
    icon: <Icon.Truck size={20} />,
    permissions: ["FLORIST", "ADMIN"],
    navLink: "/delivery",
  },

  {
    id: "deliveryapi",
    title: "deliveryApi",
    type: "item",
    icon: <Icon.Truck size={20} />,
    permissions: ["ADMIN"],
    navLink: "/deliveriesApi",
  },

  {
    id: "payment",
    title: "Paiement",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["FLORIST"],
    navLink: "/settings/payment",
  },

  {
    id: "marketings",
    title: "Marketing",
    type: "collapse",
    icon: <UilMegaphone size={20} />,
    permissions: ["ADMIN", "FLORIST"],

    children: [
      {
        id: "banner",
        title: "pub",
        type: "item",

        permissions: ["FLORIST", "ADMIN"],
        navLink: "/banner",
      },
      /*   {
        id: "marketing",
        title: "Notifications",
        type: "item",

        permissions: ["ADMIN", "FLORIST"],
        navLink: "/pages/pushNotifications/listNotifications",
      }, */
    ],
  },

  {
    id: "support",
    title: "Support",
    type: "collapse",
    icon: <UilMegaphone size={20} />,
    permissions: ["FLORIST"],

    children: [
      {
        id: "faq",
        title: "FAQ",
        type: "item",
        permissions: ["FLORIST"],
        navLink: "/faq",
      },
      {
        id: "contact",
        title: "Nous contacter",
        type: "item",
        permissions: ["FLORIST"],
        navLink: "/contact",
      },
    ],
  },
  // {
  //   id: "PageDone",
  //   title: "Page Done",
  //   type: "collapse",
  //   icon: <Icon.Layout size={20} />,
  //   permissions: ["ADMIN", "FLORIST"],
  //   children: [
  //     {
  //       id: "subscription",
  //       title: "Abonnements",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/pages/subscription/subscription",
  //     },
  //     {
  //       id: "Legal",
  //       title: "Legal",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/pages/legal/Legal",
  //     },
  //     {
  //       id: "registerPage",
  //       title: "Sinscrire",
  //       type: "item",
  //       icon: <Icon.Circle size={12} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/pages/registerPage/Intro",
  //     },
  //   ],
  // },
];

export default horizontalMenuConfig;
